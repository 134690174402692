
import { getCurrentInstance } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: '#',
    },
    featureName: {
      type: String,
      default: '',
    },
    isNewFooterStyle: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {
    const instance = getCurrentInstance();
    const { $config } = instance!.proxy;
    const siteUrl = $config?.SITE_URL;

    return {
      siteUrl,
    };
  },
});
