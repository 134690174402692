export const truncate = (text: string, limit: number): string => {
  const textTrimed = text.trim();

  if (!text) {
    return '';
  }

  if (textTrimed.length <= limit) {
    return text;
  }

  return `${textTrimed.slice(0, limit).trim()}...`;
};
