import { Ref } from '@nuxtjs/composition-api';
import {
  BillingRegion,
  PersonalDetails,
  EcommerceBillingInformation,
  EcommerceCourseDetails,
  ProductCost,
} from '@/shared/types';
import {
  EcommerceBasketDataResponse,
  EcommercePersonalDetailsData,
} from '~/composables/ecommerce/useEcommerce.types';
import { LearnerDetails } from '~/shared/types/common/common.types';

export enum BookingPaymentMethod {
  CARD = 'card',
  BANK_TRANSFER = 'bank_transfer',
  PAY_BY_QUOTE = 'quote',
  PAYPAL = 'paypal',
  UNKNOWN = 'unknown',
}

export type BookingInformationForm = {
  expirationDate: string;
  paymentMethod: BookingPaymentMethod;
  courseDateId: string;
  streetAddress1: string;
  streetAddress2: string;
  companyName: string;
  city: string;
  postcode: string;
  country: { label?: string; code?: string };
  region: BillingRegion | string;
};

export type BookingInformationResponseErrors = {
  [key: string]: string;
};

export type BookingInformationBasketWatch = [
  Ref<EcommerceBasketDataResponse | undefined>,
  Ref<EcommercePersonalDetailsData>
];

export type DiscountSection = {
  discountValueWithCurrency?: string;
};

export type ReviewOrderAndDiscountResponse = {
  personalDetails?: PersonalDetails;
  billingInformation?: EcommerceBillingInformation;
  courseDetails?: EcommerceCourseDetails;
  courseCost: ProductCost;
  amIparticipating?: boolean;
  participants?: LearnerDetails[];
};

export enum CardErrorTypes {
  CARD = 'card',
  ADDRESS = 'address',
  GENERAL = 'general',
}

export enum PaymentButtonText {
  CARD = 'Pay',
  PAY_BY_QUOTE = 'Generate a quote',
}

export type PaymentStatus = {
  paymentError?: boolean;
  paymentPending?: boolean;
};

export type PaymentErrorCodes = {
  errorCode?: number;
  statusCode?: string;
};
