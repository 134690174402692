
import { getCurrentInstance } from 'vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import FooterNavigationItem from './components/FooterNavigationItem.vue';
import { FooterColumnHeadings } from './Footer.enums';
import SocialMediaLogoSet from '@/components/social-media/SocialMediaLogoSet.vue';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import LogoSet from '@/components/logo/LogoSet.vue';
import { NavigationItem } from '@/shared/types/store';
import { useCaoBranding } from '~/composables/branding/useCaoBranding';
import { FeatureFlagName } from '~/shared/types/feature-flags/feature-flags';
export default defineComponent({
  components: {
    SectionContentContainer,
    FooterNavigationItem,
    LogoSet,
    SocialMediaLogoSet,
  },
  setup: () => {
    const instance = getCurrentInstance();
    const { hasCaoBranding } = useCaoBranding();
    const { $store, $isFeatureEnabled } = instance!.proxy;
    const isB2bEnabled = $isFeatureEnabled(FeatureFlagName.USE_B2B_PAGES);
    const { ADVANCE_ONLINE, FOR_ORGANISATIONS, LEGAL } = FooterColumnHeadings;
    const navItemsToBeFilteredOut = ['Courses', 'For organisations', 'Blog'];

    const mainNavItemsFromStore = computed<NavigationItem[]>(() => {
      return hasCaoBranding.value
        ? $store.state.navigation.main
        : $store.state.navigation.main.filter(
            (item: NavigationItem) =>
              !navItemsToBeFilteredOut.includes(item.label)
          );
    });

    const mainNavItems = computed<NavigationItem[]>(() => {
      if (isB2bEnabled && hasCaoBranding.value) {
        const newMainNavItems = mainNavItemsFromStore.value.filter(
          (item: NavigationItem) => item.newFooterColumn === 1
        );
        return newMainNavItems;
      }
      return mainNavItemsFromStore.value;
    });

    const infoPagesNavItems = computed<NavigationItem[]>(() => {
      const infoPages = $store.state.navigation.infoPages;
      if (isB2bEnabled && hasCaoBranding.value) {
        const otherInfoPages = mainNavItemsFromStore.value.filter(
          (item: NavigationItem) => item.newFooterColumn === 3
        );
        return [...otherInfoPages, ...infoPages];
      }
      return infoPages;
    });

    const forOrganisationsNavItems = computed(() => {
      if (isB2bEnabled && hasCaoBranding) {
        const forOrganisations = mainNavItemsFromStore.value.filter(
          (item: NavigationItem) => item.newFooterColumn === 2
        );
        return forOrganisations[0].dropdownOptions;
      }
    });

    const currentYear = new Date().getUTCFullYear();

    return {
      mainNavItems,
      infoPagesNavItems,
      currentYear,
      hasCaoBranding,
      isB2bEnabled,
      forOrganisationsNavItems,
      ADVANCE_ONLINE,
      FOR_ORGANISATIONS,
      LEGAL,
    };
  },
});
