
import { getCurrentInstance } from 'vue';
import {
  defineComponent,
  reactive,
  computed,
  onUpdated,
  ref,
} from '@nuxtjs/composition-api';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap.cjs';
import { MobileNavigationState } from '../navigation.types';
import MobileNavItem from './MobileNavItem.vue';
import BaseButton from '@/components/buttons/base-button/BaseButton.vue';
import { NavigationItem } from '@/shared/types/store';
import Icon from '~/components/icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
    MobileNavItem,
    BaseButton,
  },
  setup: (_, ctx) => {
    const instance = getCurrentInstance();
    const mobileMenu = ref(null);
    const { $store } = instance!.proxy;
    const state = reactive<MobileNavigationState>({
      isToggled: false,
    });

    const { activate, deactivate } = useFocusTrap(mobileMenu);

    const navItems = computed<NavigationItem[]>(() =>
      $store.state.navigation.main.filter(
        (item: NavigationItem) => item.isDisplayedInMainNav
      )
    );

    const toggleHandler = () => {
      state.isToggled = !state.isToggled;
      if (state.isToggled) {
        activate();
        return;
      }
      deactivate();
    };

    onUpdated(() => {
      const body = document.body;
      if (body) {
        if (state.isToggled) {
          body.classList.add('mobile-nav-enabled');
        } else {
          body.classList.remove('mobile-nav-enabled');
        }
      }
    });

    return { state, navItems, toggleHandler, mobileMenu };
  },
});
