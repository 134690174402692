// @ts-nocheck
import {
  MainNavigationState,
  NavigationSubItem,
  StoreActionContext,
} from '@/shared/types/store';
import { API } from '~/config/api.config';
import { FeatureFlagName } from '~/shared/types/feature-flags/feature-flags';

export const state = (): MainNavigationState => [
  {
    _id: 1,
    label: 'Courses',
    path: '/courses',
    ariaLabel: 'View course page listing',
    isDisplayedInMainNav: true,
    newFooterColumn: 1,
  },
  {
    _id: 2,
    label: 'For organisations',
    path: '/for-organisations',
    isDisplayedInMainNav: true,
    isDropdown: true,
    newFooterColumn: 2,
  },
  {
    _id: 3,
    label: 'About',
    path: '/about',
    ariaLabel: 'About Cambridge Advance Online',
    isDisplayedInMainNav: true,
    newFooterColumn: 1,
  },
  {
    _id: 4,
    label: 'Blog',
    path: '/blog',
    ariaLabel: 'Blog',
    isDisplayedInMainNav: true,
    featureName: FeatureFlagName.USE_BLOG,
    newFooterColumn: 1,
  },
  {
    _id: 5,
    label: 'Contact us',
    path: '/contact-us',
    isDisplayedInMainNav: true,
    newFooterColumn: 1,
  },
  {
    _id: 6,
    label: 'FAQs',
    path: '/faqs',
    ariaLabel: 'F.A.Qs',
    newFooterColumn: 1,
  },
  {
    _id: 7,
    label: 'Policies',
    path: '/policies',
    ariaLabel: 'Policies',
    newFooterColumn: 3,
  },
];

export const mutations = {
  SET_DROPDOWN_OPTIONS(
    state: MainNavigationState,
    payload: { id: number; options: NavigationSubItem[] }
  ) {
    const itemToUpdate = state.find((item) => item._id === payload.id);
    itemToUpdate && (itemToUpdate.dropdownOptions = payload.options);
  },
};

export const actions = {
  getDropdownOptions(context: StoreActionContext): Promise<void> {
    return this.$axios
      .get(API.navigation.b2bPages)
      .then((res) => {
        const options: NavigationSubItem[] = res.data.map((option) => ({
          _id: option.id,
          label: option.title,
          path: option.path,
        }));
        return context.commit('SET_DROPDOWN_OPTIONS', {
          id: 2,
          options,
        });
      })
      .catch((error) => this.$sentry.captureException(error));
  },
};
