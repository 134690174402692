import { render, staticRenderFns } from "./FooterNavigationItem.vue?vue&type=template&id=5fb1f424&scoped=true&"
import script from "./FooterNavigationItem.vue?vue&type=script&lang=ts&"
export * from "./FooterNavigationItem.vue?vue&type=script&lang=ts&"
import style0 from "./FooterNavigationItem.vue?vue&type=style&index=0&id=5fb1f424&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb1f424",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OlsLink: require('/usr/src/nuxt-app/src/components/ols-link/OlsLink.vue').default})
