import { BookingState, StoreActionContext } from '@/shared/types/store';

export const state = (): BookingState => ({
  selectedCourseDateProductSku: '',
});

export const mutations = {
  SET_SELECTED_COURSE_DATE_PRODUCT_SKU(
    state: BookingState,
    productSku: string
  ) {
    state.selectedCourseDateProductSku = productSku;
  },
};

export const actions = {
  setSelectedCourseDateProductSku(
    context: StoreActionContext,
    productSku: string
  ): void {
    context.commit('SET_SELECTED_COURSE_DATE_PRODUCT_SKU', productSku);
  },
};
