export const UTM_AVAILABLE_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

export const UTM_VALUES_LOCAL_STORAGE_KEY = 'ols-utm-values';

export const UTM_VALUES_LAST_UPDATE_LOCAL_STORAGE_KEY =
  'ols-utm-values-last-update-time';
