
import { getCurrentInstance } from 'vue';
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import MainContent from '@/components/main-content/MainContent.vue';

export default defineComponent({
  components: {
    SectionContentContainer,
    MainContent,
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  setup: () => {
    const instance = getCurrentInstance();
    // @ts-ignore
    const { error, $route } = instance!.proxy;

    onMounted(() => {
      if ($route.path === '/login/proxy/error') {
        error({
          statusCode: 407,
          message: 'Failed to authorize via proxy',
        });
      }
    });
  },
});
